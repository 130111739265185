import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {trackEvent} from "util/eventUtil";
import {TrackingEventType} from "@pinch-financial/pinch-ui-components";
import {getNavigationError} from "store/selectors/navigationController";
import {getFixedT} from "util/languageUtil";
import {PurchaseTimeframe} from "types/enums/purchaseTimeframe";
import {PurchaseTimeframePage as PurchaseTimeframePageUI} from "@pinch-financial/pinch-ui-components";
import {getContactInfo, getIsLocked} from "store/selectors/basicInfo";
import {getPurchaseTimeframe} from "store/selectors/applicantMeta";
import i18next from "i18next";
import NavigationBar from "components/NavigationBar/NavigationBar";
import SaveButton from "components/SaveButton/SaveButton";
import {submit} from "./PurchaseTimeframePage.action";

const fixedT = getFixedT("purchaseTimeframePage");

interface Props {
  className?: () => any;
  onPrevPage?: () => any;
  onNextPage?: () => any;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const PurchaseTimeframePage: React.FC<Props> = ({
  className = "",
  onPrevPage = () => {},
  onNextPage = () => {},
  containerRef,
}) => {
  const locked = useSelector(getIsLocked);
  const dispatch = useDispatch();
  const {email} = useSelector(getContactInfo);
  const navigationError = useSelector(getNavigationError);
  const [fieldError, setFieldError] = useState<string>();
  const storedPurchaseTimeframe = useSelector(getPurchaseTimeframe);
  const [purchaseTimeframe, setPurchaseTimeframe] = useState(storedPurchaseTimeframe);

  function onSetPurchaseTimeframe(
    purchaseTimeframe: PurchaseTimeframe,
    trackingEventType: TrackingEventType
  ) {
    trackEvent(trackingEventType);
    setPurchaseTimeframe(purchaseTimeframe);
    dispatch(submit({purchaseTimeframe, onNextPage}));
  }

  useEffect(() => {
    setFieldError(navigationError ?? "");
  }, [navigationError]);

  return (
    <PurchaseTimeframePageUI
      containerRef={containerRef}
      header={fixedT("header")}
      description={fixedT("description")}
      tipHeader={fixedT("tipHeader")}
      tipBody={<span dangerouslySetInnerHTML={{__html: fixedT("tipBody", {email: email})}} />}
      onPrevPage={() => {
        trackEvent(TrackingEventType.purchaseTimeframePageClickBackButton);
        onPrevPage();
      }}
      navigationBar={<NavigationBar />}
      saveAndExitScrollable={<SaveButton displayFor="non-mobile" />}
      backButtonText={i18next.t("backButton")}
      storedPurchaseTimeframe={purchaseTimeframe}
      pageError={fieldError}
      disabled={locked}
      onClickWithin30Days={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.WITHIN_30_DAYS,
          TrackingEventType.purchaseTimeframePageWithin30Days
        );
      }}
      within30DaysTitle={fixedT("timeframe.within30Days")}
      onClick2to3Months={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.BETWEEN_2_TO_3_MONTHS,
          TrackingEventType.purchaseTimeframePage2to3Months
        );
      }}
      between2to3MonthsTitle={fixedT("timeframe.2to3Months")}
      onClick3to6Months={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.BETWEEN_3_TO_6_MONTHS,
          TrackingEventType.purchaseTimeframePage3to6Months
        );
      }}
      between3to6MonthsTitle={fixedT("timeframe.3to6Months")}
      onClickMoreThan6Months={() => {
        onSetPurchaseTimeframe(
          PurchaseTimeframe.MORE_THAN_6_MONTHS,
          TrackingEventType.purchaseTimeframePageMoreThan6Months
        );
      }}
      moreThan6MonthsTitle={fixedT("timeframe.moreThan6Months")}
      iconAlt={fixedT("iconAlt")}
    />
  );
};

export default PurchaseTimeframePage;
