import {createSelector} from "@reduxjs/toolkit";
import {ApplicantMetaState} from "store/reducers/applicantMeta";
import {State} from "types/store";

export const getApplicantMeta = (rootState: State): ApplicantMetaState => rootState.applicantMeta;

export const getApplicantMetaBasicInfoOrEmpty = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.basicInfo || {}
);

export const getHasUserAcceptedAssetsDataWarning = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.hasUserAcceptedAssetsDataWarning
);

export const getHasUserAcceptedLiabilitiesDataWarning = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.hasUserAcceptedLiabilitiesDataWarning
);

export const getHasUserAcceptedDocumentUploadWarning = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.hasUserAcceptedDocumentUploadWarning
);

export const getShouldUserAcceptedAssetsDataWarningModalOpen = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.shouldUserAcceptedAssetsDataWarningModalOpen
);

export const getShouldUserAcceptedLiabilitiesDataWarningModalOpen = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.shouldUserAcceptedLiabilitiesDataWarningModalOpen
);

export const getShouldUserAcceptedDocumentUploadWarningModalOpen = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.shouldUserAcceptedDocumentUploadWarningModalOpen
);

export const getPurchaseTimeframe = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => applicantMetaBasicInfo?.purchaseTimeframe
);

export const getUserCreditScore = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => applicantMetaBasicInfo?.creditScore
);

export const getLoanType = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => applicantMetaBasicInfo?.mortgageDetails?.loanType
);

export const getUserDateOfBirth = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => applicantMetaBasicInfo?.dateOfBirth
);

export const getSelfReportedAssets = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => applicantMetaBasicInfo?.selfReportedAssets
);

export const getHasSelfReportedAssets = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => Boolean(applicantMetaBasicInfo?.selfReportedAssets?.length)
);

export const getSelfReportedLiabilities = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => applicantMetaBasicInfo?.selfReportedLiabilities
);

export const getHasSelfReportedLiabilities = createSelector(
  getApplicantMetaBasicInfoOrEmpty,
  (applicantMetaBasicInfo) => Boolean(applicantMetaBasicInfo?.selfReportedLiabilities?.length)
);

export const getHasUserSelectedProvider = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.hasUserSelectedProvider
);

export const getPageStates = createSelector(
  getApplicantMeta,
  (applicantMeta) => applicantMeta?.pageStates ?? {}
);

export const getTargetPageState = createSelector(
  getPageStates,
  (pageStates) => (targetPageName: string) => pageStates[targetPageName]
);
