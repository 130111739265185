import {WhiteLabelDetailsDto} from "types/WhiteLabelDetailsDto";
import {WithDataError} from "types/api";
import {normalizedFetchApi} from "./util";
import {WhiteLabelProductGroupDto} from "types/dto/WhiteLabelProductGroupDto";
import {UUID} from "@pinch-financial/pinch-ui-components";
import queryString from "query-string";
import {Province} from "types/enums/province";

const baseUrl = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/whiteLabels`;

export const fetchWhiteLabelApi = async (
  whitelabelNameOrPlatformInTheme: string
): Promise<WithDataError<WhiteLabelDetailsDto>> => {
  return normalizedFetchApi(() => fetch(`${baseUrl}/name/${whitelabelNameOrPlatformInTheme}`)); // We can't switch from name since that's how we create a theme
};

export const fetchMortgageProvidersApi = async (
  whitelabelId: UUID,
  mortgageProviderId: UUID | undefined,
  province: Province | undefined
): Promise<WithDataError<WhiteLabelProductGroupDto>> => {
  const queryParams = queryString.stringify(
    {
      groupByFinancialInstitution: true,
      mortgageProviderId: mortgageProviderId || undefined,
      province: province || undefined,
    },
    {skipNull: true}
  );

  const url = `${baseUrl}/products/${whitelabelId}?${queryParams}`;

  return normalizedFetchApi(() => fetch(url));
};
