import {UUID} from "@pinch-financial/pinch-ui-components";
import {normalizedFetchApi} from "./util";
import {WithDataError} from "types/api";
import {FinancialInstitutionBasicResponse} from "types/financialInstitutionBasicResponse";
import queryString from "query-string";

const baseUrl = `${window._env_.REACT_APP_PINCH_SERVICE_API_HOST}/financial-institutions`;

export async function fetchFinancialInstitutionByIdApi(
  financialInstitutionId: UUID,
  province: string
): Promise<WithDataError<FinancialInstitutionBasicResponse>> {
  const queryParams = queryString.stringify({province}, {skipNull: true, skipEmptyString: true});
  return normalizedFetchApi(() => fetch(`${baseUrl}/id/${financialInstitutionId}?${queryParams}`));
}

// For activation page & secondary signup page
export async function fetchFinancialInstitutionByAppOrAuthIdApi(
  residentialApplicationId?: UUID,
  userExternalAuthId?: string
): Promise<WithDataError<FinancialInstitutionBasicResponse>> {
  const queryParams = queryString.stringify(
    {
      residentialApplicationId,
      userExternalAuthId,
    },
    {skipNull: true, skipEmptyString: true}
  );
  return normalizedFetchApi(() => fetch(`${baseUrl}/appId-or-authId?${queryParams}`));
}
