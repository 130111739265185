import {createSelector} from "@reduxjs/toolkit";
import {
  getCurrentRouteUrlValue,
  getHasSelfReportedAssetsInApp,
  getHasSelfReportedLiabilitiesInApp,
} from "./basicInfo";
import {
  getHasSelfReportedAssets as getHasSelfReportedAssetsInMeta,
  getHasSelfReportedLiabilities as getHasSelfReportedLiabilitiesInMeta,
} from "./applicantMeta";
import {getActiveRouteSequenceVisible, getFirstRouteUrlAfterSignUp} from "./navigationController";
import {AppRouteUrl} from "@pinch-financial/pinch-ui-components";
import {getAppAssociatedFinancialInstitution} from "./financialInstitution";
import {getSelectedProvider} from "./whitelabel";

export const getHasSelfReportedAssets = createSelector(
  getHasSelfReportedAssetsInApp,
  getHasSelfReportedAssetsInMeta,
  (appHasSelfAssets, metaHasSelfAssets) => appHasSelfAssets || metaHasSelfAssets
);

export const getHasSelfReportedLiabilities = createSelector(
  getHasSelfReportedLiabilitiesInApp,
  getHasSelfReportedLiabilitiesInMeta,
  (appHasSelfLiabilities, metaHasSelfLiabilities) => appHasSelfLiabilities || metaHasSelfLiabilities
);

export const getCurrentRouteOrFirstAfterSignUp = createSelector(
  getCurrentRouteUrlValue,
  getFirstRouteUrlAfterSignUp,
  getActiveRouteSequenceVisible,
  (currentRoute, firstRoute, visibleRoutes): AppRouteUrl => {
    return currentRoute && visibleRoutes?.includes(currentRoute) ? currentRoute : firstRoute;
  }
);

// Financial Institution Contact Info
export const getContactInfo = createSelector(
  getAppAssociatedFinancialInstitution,
  getSelectedProvider, // selected on page bank-or-broker
  (appFi, selectedProvider) => {
    if (appFi) {
      return {
        email: appFi.primaryEmail,
        phone: appFi.phoneNumber,
      };
    }
    return selectedProvider
      ? {
          email: selectedProvider.financialInstitutionPrimaryEmail,
          phone: selectedProvider.financialInstitutionPhoneNumber,
        }
      : undefined;
  }
);

export const getFinancialInstitution = createSelector(
  getAppAssociatedFinancialInstitution,
  getSelectedProvider, // selected on page bank-or-broker
  (appFi, selectedProvider) => {
    if (appFi) {
      return appFi;
    }
    return selectedProvider
      ? {
          publicId: selectedProvider.financialInstitutionPublicId,
          name: selectedProvider.financialInstitutionName,
          logo: selectedProvider.financialInstitutionLogo,
          phoneNumber: selectedProvider.financialInstitutionPhoneNumber,
          primaryEmail: selectedProvider.financialInstitutionPrimaryEmail,
          complianceText: selectedProvider.financialInstitutionComplianceText,
          complianceTextFr: selectedProvider.financialInstitutionComplianceTextFr,
          licenceNumber: selectedProvider.financialInstitutionLicenceNumber,
        }
      : undefined;
  }
);
