import authClient, {storeTokens} from "components/utils/OktaAuth/OktaAuth";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {authorizationCodeCallback, signOut} from "store/actions/auth.action";
import {fetchBasicInfoByUserIdOperation} from "store/operation/basicInfo";
import {
  fetchAuthorizedUserOperation,
  fetchProviderConfigurationsOperation,
  fetchThemeOperation,
  fetchUserApiOperation,
  signOutOperation,
} from "store/operation/operations";
import {getAppId, getBrokerCode, getMortgageProviderIdFromApp, getProvinceFromApp} from "store/selectors/basicInfo";
import {getUserId} from "store/selectors/user";
import {WithPayload} from "types/basic";
import {AppRouteUrl, UUID} from "@pinch-financial/pinch-ui-components";
import {WithNavigate} from "types/ui";
import {fetchInitRouteSequenceNavStatus} from "components/NavigationBar/NavigationBar.saga";
import {getCurrentRouteOrFirstAfterSignUp} from "store/selectors/consolidatedSelectors";
import {onFetchSelectedProvider} from "pages/SecondarySignUpPage/SecondarySignUpPage.saga";
import {PayloadAction} from "@reduxjs/toolkit";
import {fetchAppAssociatedFinancialInstitutionOperation} from "store/operation/financialInstitution";

export function* onSignOut() {
  // clears all local user data: okta, auth state, user account
  yield call(signOutOperation.saga);
}

export function* onAuthorizationCodeCallback({payload: {navigate}}: WithPayload<WithNavigate>) {
  // Prevent the code from running twice, by only running if the code has query parameters.
  if (location.search && authClient.isLoginRedirect()) {
    try {
      yield call(storeTokens);
    } catch (e) {
      console.error("Error storing tokens", e);
      navigate && navigate(AppRouteUrl.SIGN_IN);
      return;
    }

    // if BE stores where user were, we can redirect properly
    yield call(fetchAuthorizedUserOperation.saga);
    yield call(fetchUserApiOperation.saga);
    const userId: string = yield select(getUserId);
    yield call(fetchBasicInfoByUserIdOperation.saga, userId);
    const brokerCode: string = yield select(getBrokerCode);
    yield call(fetchThemeOperation.saga, brokerCode);
    const mortgageProviderId: string = yield select(getMortgageProviderIdFromApp); // user's app must have mortgage provider
    const province: string = yield select(getProvinceFromApp); // user's app must have mortgage provider
    yield call(fetchAppAssociatedFinancialInstitutionOperation.saga, mortgageProviderId, province);
    yield call(fetchProviderConfigurationsOperation.saga, mortgageProviderId);

    // fetch provider navbar config
    const appId: string = yield select(getAppId);
    yield call(onFetchSelectedProvider, {payload: {appId}} as PayloadAction<{appId: UUID}>);

    yield call(fetchInitRouteSequenceNavStatus);

    const nextRoute: AppRouteUrl = yield select(getCurrentRouteOrFirstAfterSignUp);
    console.info("Redirect after sign-in", nextRoute);
    navigate(nextRoute);
  }
}

export default function* authSaga() {
  yield takeEvery(signOut, onSignOut);
  yield takeEvery(authorizationCodeCallback, onAuthorizationCodeCallback);
}
