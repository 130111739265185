import {createAction} from "@reduxjs/toolkit";
import {actionTypeOf} from "./util";
import {ResidentialApplicationBasicInfoRequest} from "@pinch-financial/pinch-ui-components";

const namespace = "applicantMeta";

export const saveBasicInfo = createAction<Partial<ResidentialApplicationBasicInfoRequest>>(
  actionTypeOf(namespace, "saveBasicInfo")
);

export const unsetTargetPageState = createAction<string>(actionTypeOf(namespace, "unsetPageState"));
